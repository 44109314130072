//Assets
import img from '../../../../img/bendo/gmm.png'


const GenerateSiteMaps = ({grid}) => {
    return (
        <div className={`bento-gsm__block ${grid}`}>

            <h3 className={'bento__title-h4'}>Generate Site Maps</h3>
            <div className="bento-gsm__wrapper">
                <div className={`bento-gsm__blocks`}>
                    <div className={`bento-gsm__point bento-gsm__point--wide`}>Home page</div>


                    <div className={`bento-gsm__structure`}>

                        <div className={`bento-gsm__point`}>Products</div>
                        <div className={`bento-gsm__point`}>Special Offers</div>
                        <div className={`bento-gsm__point`}>Cart</div>
                        <div className={`bento-gsm__point`}>Support</div>
                        <div className={`bento-gsm__point`}>Blog</div>
                        <div className={`bento-gsm__point`}>Partners</div>
                    </div>
                </div>
            </div>


        </div>
    )
}
export default GenerateSiteMaps