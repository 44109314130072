import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    data: {
      loginned: false,
      token: '',
      refreshToken: '',
      email: '',
      googleId: null,
      fullName: '',
      picture: '',
      subscript: {
        name: '',
        url: '',
        status: '',
      },
      writeToken: null,
      loginExpiration: null,
    },
  },
  reducers: {
    updateData(state, action) {
      state.data.token = action.payload.token;
      state.data.refreshToken = action.payload.refreshToken;
      state.data.email = action.payload.email;
      state.data.googleId = action.payload.googleId;
      state.data.fullName = action.payload.fullName;
      state.data.picture = action.payload.picture;
      state.data.loginned = action.payload.loginned;

      if(action.payload.subscript) {
        state.data.subscript = action.payload.subscript;
      }


    },
    updateWriteToken(state, action) {
      state.data.writeToken = action.payload.writeToken;
    },
    isRefreshToken(state, action) {
      state.data.token = action.payload.token;
      state.data.refreshToken = action.payload.refreshToken;
      localStorage.setItem('userData', JSON.stringify(state.data));
    },
    updateSubscribe(state, action) {
      state.data.subscript.name = action.payload.name;
      state.data.subscript.url = action.payload.url;
      state.data.subscript.status = action.payload.status;
      localStorage.setItem('userData', JSON.stringify(state.data));
    },
    userLogout(state) {
      const clean = {
        loginned: false,
        token: '',
        refreshToken: '',
        email: '',
        googleId: null,
        fullName: '',
        picture: '',
        subscript: {
          name: '',
          url: '',
          status: '',
        },
        writeToken: null,

      };
      localStorage.clear();
      state.data = clean;
    },
  },
});
export const {
  updateData,
  updateSubscribe,
  updateWriteToken,
  userLogout,
  isRefreshToken,
} = userSlice.actions;

export default userSlice.reducer;