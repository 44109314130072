// Import React
import React, {useEffect} from 'react';

import Pricing from '../components/Pricing/Pricing';

import VideoSection from '../components/VideoSection/VideoSection';
import Promo from '../components/LandingSections/Promo';
import Slider from '../components/LandingSections/Slider';
import Gallery from '../components/LandingSections/Gallery';
import Faq from '../components/LandingSections/Faq';
import Info from '../components/LandingSections/Info';
import Bento from "../components/LandingSections/Bento/Bento";
import ProblemSolving from "../components/LandingSections/ProblemSolving";

const Main = () => {
    return (
        <main className='landing'>
            <Promo/>

            {/*<ProblemSolving/>*/}


            <Bento/>
            <Pricing title={'Pricing'}
                  desc={'Test AI Designer Pro at no cost for 1 week. No complicated deals or extra things to worry about—just one plan for everything.'}
                />
                <Faq />
        </main>
    );
};

export default Main;
