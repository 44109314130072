//Import React
import React, { useEffect, useRef, useState } from "react";

//Import react-intersection-observer
import { useInView } from "react-intersection-observer";
import { gsap } from 'gsap'
import TextPlugin from './TextPlugin.js';
import GSDevTools from './GSDevTools.js';

//Import icons
import figmaIco from "../../img/Icons.svg";
import firstAnimImg from "../../img/first-anim-img.png";
import firstAnimImg2 from "../../img/first-anim-img2.png";
import firstAnimImg3 from "../../img/first-anim-img3.png";
import fourAnimationBorder from "../../img/four-animation-border.png";
import fourAnimationBorder2 from "../../img/four-animation-border2.png";
import fourAnimationBorder3 from "../../img/four-animation-bottom.png";
import secondAnimation from "../../img/second-animation.gif";
import thirdAnimation from "../../img/third-animation.png";
import thirdAnimationCol from "../../img/third-animation-col.png";
import thirdAnimationCol2 from "../../img/third-animation-col2.png";
import thirdAnimationCol3 from "../../img/third-animation-col3.png";
import thirdAnimationCol4 from "../../img/third-animation-col4.png";
import thirdAnimationCol5 from "../../img/third-animation-col5.png";
import thirdAnimationCol6 from "../../img/third-animation-col6.png";
import canvasLeft from "../../img/canvas-left.svg";
import canvasRight from "../../img/canvas-right.png";

import firstAnimMob from "../../img/firstAnimMob.png";
import firstAnimMob2 from "../../img/firstAnimMob2.png";
import firstAnimMob3 from "../../img/firstAnimMob3.png";
import firstAnimMob4 from "../../img/firstAnimMob4.png";
import firstAnimMob5 from "../../img/firstAnimMob5.png";
import firstAnimMob6 from "../../img/firstAnimMob6.png";
import firstAnimMob7 from "../../img/firstAnimMob7.png";
import firstAnimMob8 from "../../img/firstAnimMob8.png";


const Promo = () => {
    const [animation, setAnimation] = useState(false);

    const video = useRef(null);

    useEffect(() => {
        if (video.current) {
            video.current.play();
        }
    }, [video]);

    const { ref, inView, entry } = useInView({
        threshold: 0,
    });

    useEffect(() => {
        if (inView === true) {
            setAnimation(true);
        }
    }, [inView]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);


    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            setIsMobile(window.innerWidth <= 1200);

        };

        window.addEventListener("resize", handleResize);


        gsap.defaults({ ease: "power3.inOut" });

        gsap.registerPlugin(TextPlugin);
        gsap.registerPlugin(GSDevTools);

        // Default timeline
        let master = gsap.timeline({});

        const tlDefault = {
            defaults: {
                immediateRender: false,
                ease: 'power4.easeIn',
            }
        }


        // Texts
        const text = {
            first: "A fairy-tale medieval town, early morning",
            second: "Create an animation: children playing football in Barcelona",
            third: "User persona for a furniture shopping app",
            four: "Come up with descriptions for restaurant dishes",
            textFourAnimTop: "Spaghetti Carbonara",
            textFourAnimCenter: {
                one: "Traditional Roman pasta dish made with",
                two: "eggs, Pecorino Romano cheese, pancetta,",
                three: "and black pepper. Its creamy,rich texture",
                four: "is achieved without the use of any",
                five: "creams, making it a true Italian favorite.",
            },
            leftBlockText: {
                one: "Your Invisible",
                two: "Assistant For Figma",
                three: "Designs"
            }
        };


        // Box elements
        const boxTop = document.querySelector(".box-anim-top");
        const boxInput = document.querySelector(".box-anim-input");
        const fourAnimTopText = document.querySelector(".four-animation__top--text");
        const fourAnimCenterText = document.querySelectorAll(".four-animation__center--text p");
        const promoTitle = document.querySelectorAll('.promo__title span')

        // Collback functions
        const addClassFocus = () => boxTop.classList.add("focus");
        const removeClassFocus = () => boxTop.classList.remove("focus");
        const addLineAfterText = (wrapper) => wrapper.classList.add('blinking-cursor');
        const removeLineAfterText = (wrapper) => wrapper.classList.remove('blinking-cursor');

        const btnClick = () => {
            return gsap.timeline()
                .to('.box-anim-btn--move', { width: "93%", height: "90%", duration: 0.3, delay: 0.1 })
                .to('.box-anim-btn--text', { opacity: 0.7, duration: 0.3 }, "<")
                .to('.box-anim-btn--move', { width: "100%", height: "100%", duration: 0.3 })
                .to('.box-anim-btn--text', { opacity: 1, duration: 0.3 }, "<");
        }

        let speedWriteText = 0.3

        const writeText = (text, wrapper, duration) => {
            return gsap.timeline()
                .call(addLineAfterText, [wrapper])
                .fromTo(wrapper, { text: "" }, { text: { value: text }, ease: 'none', duration: duration }, "<")
                .call(removeLineAfterText, [wrapper], 0.7)
        }


        const hideBlock = (block, delay = 0) => {
            return gsap.timeline()
                .to(block, { opacity: 0, duration: 0.1, delay: delay })
        }

        // Left animation
        function leftBlockAnim() {
            return gsap.timeline(tlDefault)
                .to('.promo__content', { opacity: 1, y: 0, duration: 1 })
                .to('.promo__fon', { opacity: 1, duration: 1 }, '-=0.6')
                .to('.promo__dot--grid', { opacity: 1, duration: 1 }, '-=0.8')
                .add(writeText(text.leftBlockText.one, promoTitle[0], 0.50))
                .add(writeText(text.leftBlockText.two, promoTitle[1], 0.50))
                .add(writeText(text.leftBlockText.three, promoTitle[2], 0.40))
                .to('.promo__subtitle', { y: 0, opacity: 1, duration: 0.8 })
                .to('.promo__btns', { y: 0, opacity: 1, duration: 0.8 }, '-=0.4')
                .to('.box-anim', { opacity: 1, duration: 0.8 }, '-=1.6')
        }


        // First animation
        function firstAnim() {
            return gsap.timeline(tlDefault)
                .to(boxTop, { onUpdate: addClassFocus, onReverseComplete: removeClassFocus, duration: 0.3 })
                .add(writeText(text.first, boxInput, speedWriteText))
                .add(btnClick())
                .to('.first-animation img', { scale: 1, opacity: 1, stagger: 0.4, duration: 0.8 });
        }


        // Second animation
        function secondAnim() {
            return gsap.timeline(tlDefault)
                .add(hideBlock('.first-animation', 0.5))
                .add(writeText(text.second, boxInput, speedWriteText))
                .add(btnClick())
                .to('.second-animation--gif', { scale: 1, duration: 0.8 }, '-=0.5')
                .to('.second-animation--gif', { opacity: 1, duration: 1 }, '<')
        }

        // Third animation
        function thirdAnim() {
            return gsap.timeline(tlDefault)
                .add(hideBlock('.second-animation', 1.5))
                .add(writeText(text.third, boxInput, speedWriteText))
                .add(btnClick())
                .to('.third-animation__top img', { scale: 1, opacity: 1, duration: 0.4 })
                .to('.third-animation__col--one img', { scale: 1, opacity: 1, stagger: 0.2 }, '-=0.2')
                .to('.third-animation__col--two img', { scale: 1, opacity: 1, stagger: 0.2 }, '<')
        }

        // Four animation
        function fourAnim() {
            return gsap.timeline(tlDefault)
                .add(hideBlock('.third-animation', 1.2))
                .add(writeText(text.four, boxInput, speedWriteText))
                .add(btnClick())
                .to('.four-animation__wrap', { scale: 1, opacity: 1, duration: 1 })
                .to('.four-animation__top img', { scale: 1, opacity: 1, duration: 1 })
                .add(writeText(text.textFourAnimTop, fourAnimTopText, 0.5))
                .to('.four-animation__center img', { scale: 1, opacity: 1, duration: 1 })
                .add(writeText(text.textFourAnimCenter.one, fourAnimCenterText[0], 0.52))
                .add(writeText(text.textFourAnimCenter.two, fourAnimCenterText[1], 0.52))
                .add(writeText(text.textFourAnimCenter.three, fourAnimCenterText[2], 0.52))
                .add(writeText(text.textFourAnimCenter.four, fourAnimCenterText[3], 0.52))
                .add(writeText(text.textFourAnimCenter.five, fourAnimCenterText[4], 0.52))
                .to('.four-animation__bottom img', { y: 0, opacity: 1, duration: 1 }, '-=1')
        }

        let currentIndex = 0;

        function toggleShow() {
            const blocks = document.querySelectorAll('.animation-scane');
            const textValues = Object.values(text.textFourAnimCenter);
            const paragraphs = document.querySelectorAll('.four-animation__center--text p')

            gsap.to('.promo__content', { opacity: 1, duration: 1 })
            gsap.to('.promo__fon', { opacity: 1, duration: 1 })

            blocks.forEach((block) => {
                block.classList.remove('show');
                block.classList.add('hide');
            });

            boxInput.textContent = ''

            const animationTextMap = {
                'first-animation': text.first,
                'second-animation': text.second,
                'third-animation': text.third,
                'four-animation': text.four,
            };

            const currentBlock = blocks[currentIndex];
            const currentClass = Array.from(currentBlock.classList).find(cls => animationTextMap[cls]);

            if (currentClass) {
                boxInput.textContent = animationTextMap[currentClass];
                currentBlock.classList.remove('hide');
                currentBlock.classList.add('show');
            }

            document.querySelector('.four-animation__top--text').textContent = text.textFourAnimTop

            paragraphs.forEach((paragraph, index) => {
                if (textValues[index]) {
                    paragraph.textContent = textValues[index];
                }
            });

            currentIndex = (currentIndex + 1) % blocks.length;
        }

        /* setInterval(toggleShow, 3000); */
        // Add animations to master timeline

        if (!isMobile) {
            setTimeout(() => {
                master.add(leftBlockAnim())
                    .add(firstAnim())
                    .add(secondAnim())
                    .add(thirdAnim())
                    .add(fourAnim())
                    .then(() => {
                        setInterval(toggleShow, 3000);
                    });
            }, 2000)
        } else {
            toggleShow();
            setInterval(toggleShow, 3000);
        }


        /* GSDevTools.create({ id: "AI", startPaused: true }); */

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);


    return (
        <section
            className={`section-start promo ${animation && "animation"}`}
            ref={ref}
        >
            <span className="promo__fon"></span>
            <div className="container">
                <div className="promo__content">
                    <div className="promo__dot--grid"></div>
                    <div className="promo__canvas--top">
                        <div className="promo__canvas--left">
                            <img src={canvasLeft}
                                 alt="img"/>
                        </div>
                        <div className="promo__canvas--center">
                            Your design project / <span>AI Designer Pro</span>
                        </div>
                        <div className="promo__canvas--right">
                            <img src={canvasRight}
                                 alt="img"/>
                        </div>
                    </div>
                    {!isMobile && (
                        <div className="promo__text">
                            <h1 className={"promo__title"}>
                                <span></span>
                                <span></span>
                                <span></span>
                            </h1>
                            <p className={"promo__subtitle"}>
                                Generate everything from animations and images to complete UX and copywriting—all within seconds, directly in Figma.
                            </p>
                            <div className="promo__btns">
                                <a
                                    href="https://www.figma.com/community/plugin/1261721642878814305"
                                    target="_blank"
                                    className="button-main button-main--puprle button-main--large"
                                >
                                    <img src={figmaIco} alt="img" />
                                    Install now
                                </a>
                                <a
                                    href="#pricing"
                                    className="button-secondary button-secondary--dark button-secondary--large "
                                >
                                    Start free trial
                                </a>
                            </div>
                        </div>
                    )}
                    <div className="promo__preview">
                        <div className="box-anim">
                            <div className="box-anim-top">
                                <div className="box-anim-input"></div>
                                <div className="box-anim-btn">
                                    <span className="box-anim-btn--text">Generate</span>
                                    <span className="box-anim-btn--move"></span>
                                </div>
                            </div>
                            <div className="box-anim-result">
                                <div className="first-animation animation-scane ">
                                    <picture>
                                        <source media="(max-width: 460px)" srcSet={firstAnimMob5}/>
                                        <img className="animation-scane--mob" src={firstAnimMob} alt="img"/>
                                    </picture>
                                    <img className="first-animation--1"
                                         src={firstAnimImg}
                                         alt="img"/>
                                    <img className="first-animation--2"
                                         src={firstAnimImg2}
                                         alt="img"/>
                                    <img className="first-animation--3"
                                         src={firstAnimImg3}
                                         alt="img"/>
                                </div>
                                <div className="second-animation animation-scane">
                                    <picture>
                                        <source media="(max-width: 460px)" srcSet={firstAnimMob6}/>
                                        <img className="animation-scane--mob" src={firstAnimMob2} alt="img"/>
                                    </picture>
                                    <div className="second-animation--gif">
                                        <img src={secondAnimation}
                                             alt="gif"/>
                                    </div>
                                </div>
                                <div className="third-animation animation-scane ">
                                    <picture>
                                        <source media="(max-width: 460px)" srcSet={firstAnimMob7}/>
                                        <img className="animation-scane--mob" src={firstAnimMob3} alt="img"/>
                                    </picture>
                                    <div className="third-animation__top">
                                        <img src={thirdAnimation}
                                             alt="img"/>
                                    </div>
                                    <div className="third-animation__bottom">
                                        <div className="third-animation__col third-animation__col--one">
                                            <img src={thirdAnimationCol}
                                                 alt="img"/>
                                            <img src={thirdAnimationCol2}
                                                 alt="img"/>
                                            <img src={thirdAnimationCol3}
                                                 alt="img"/>
                                        </div>
                                        <div className="third-animation__col third-animation__col--two">
                                            <img src={thirdAnimationCol4}
                                                 alt="img"/>
                                            <img src={thirdAnimationCol5}
                                                 alt="img"/>
                                            <img src={thirdAnimationCol6}
                                                 alt="img"/>
                                        </div>
                                    </div>
                                </div>
                                <div className="four-animation animation-scane">
                                    <picture>
                                        <source media="(max-width: 460px)" srcSet={firstAnimMob8}/>
                                        <img className="animation-scane--mob" src={firstAnimMob4} alt="img"/>
                                    </picture>
                                    <div className="four-animation__wrap">
                                        <div className="four-animation__top">
                                            <img src={fourAnimationBorder}
                                                 alt="img"/>
                                            <div className="four-animation__top--text"></div>
                                        </div>
                                        <div className="four-animation__center">
                                            <img src={fourAnimationBorder2}
                                                 alt="img"/>
                                            <div className="four-animation__center--text">
                                                <p></p>
                                                <p></p>
                                                <p></p>
                                                <p></p>
                                                <p></p>
                                            </div>
                                        </div>
                                        <div className="four-animation__bottom">
                                            <img src={fourAnimationBorder3}
                                                 alt="img"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {isMobile && (
                    <div className="promo__text promo__text--hedden">
                        <h1 className={"promo__title"}>
                            Your invisible assistant <br />
                            for figma designs
                        </h1>
                        <p className={"promo__subtitle"}>
                            Generate everything from animations and images to complete UX and copywriting—all within seconds, directly in Figma.
                        </p>
                        <div className="promo__btns">
                            <a
                                href="https://www.figma.com/community/plugin/1261721642878814305"
                                target="_blank"
                                className="button-main button-main--puprle button-main--large"
                            >
                                <img src={figmaIco} alt="img" />
                                Install now
                            </a>
                            <a
                                href="#pricing"
                                className="button-secondary button-secondary--dark button-secondary--large "
                            >
                                Start free trial
                            </a>
                        </div>
                    </div>
                )}
            </div>
        </section>
    );
};

export default Promo;