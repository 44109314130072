
const GenerateUJMs = ({grid}) => {
    return (
        <div className={`bento-ujms__block ${grid}`}>

            <h3 className={'bento__title-h4'}>Generate UJMs</h3>
            <div className={`bento-ujms__img`}>
            </div>


        </div>
    )
}
export default GenerateUJMs