import React, {useState} from 'react';
import {useFormContext, Controller} from "react-hook-form";

const Input = ({name, object_key, placeholder, required}) => {

    const {register, formState: {errors,}, getFieldState} = useFormContext();
    const {control, trigger} = useFormContext();
    const [inputOk, setInputOk] = useState(false)

    return (
        <label className={`input ${errors[object_key] && 'input-error'}`} data-error='test'>
            <Controller
                name={object_key}
                control={control}
                defaultValue=""
                rules={{
                    required: 'This field is required',
                    pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
                        message: 'Password must contain at least one uppercase letter, one lowercase letter, and one digit'
                    },
                    minLength: {
                        value: 8,
                        message: 'Password must be at least 8 characters long'
                    }
                }}
                render={({field: {value, onBlur,onChange}, fieldState: {invalid, error}}) => (
                    <>
                        <input
                            value={value}
                            onBlur={() => value && trigger(object_key) && setInputOk(true)}
                            onChange={onChange}
                            type="password"
                            name={name}
                            placeholder={placeholder}
                        />
                        {error && (
                            <span className='input-error-message'>{error.message}</span>
                        )}
                        {inputOk && !invalid && <div className={'input__valid'}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="24" rx="12" fill="#302A3C"/>
                                <path
                                    d="M10.7831 17C10.6805 16.9997 10.5791 16.9783 10.4851 16.9371C10.3912 16.896 10.3067 16.836 10.237 16.7609L6.60122 12.8975C6.46531 12.7528 6.3925 12.5601 6.39882 12.3618C6.40513 12.1635 6.49004 11.9759 6.63488 11.8401C6.77972 11.7043 6.97261 11.6316 7.17113 11.6379C7.36965 11.6442 7.55753 11.7291 7.69343 11.8737L10.7756 15.1542L17.067 8.27937C17.1309 8.19995 17.2103 8.13441 17.3005 8.08677C17.3906 8.03913 17.4895 8.0104 17.5912 8.00234C17.6928 7.99428 17.795 8.00707 17.8916 8.03991C17.9881 8.07275 18.0769 8.12496 18.1524 8.19332C18.228 8.26168 18.2888 8.34476 18.3311 8.43745C18.3734 8.53014 18.3962 8.63048 18.3983 8.73232C18.4003 8.83416 18.3815 8.93533 18.343 9.02964C18.3044 9.12394 18.247 9.20938 18.1742 9.28071L14.7554 13.0171L11.3367 16.7534C11.2676 16.8299 11.1834 16.8913 11.0894 16.9337C10.9954 16.9762 10.8937 16.9987 10.7905 17H10.7831Z"
                                    fill="#CDCDCD"/>
                            </svg>
                        </div>}
                    </>
                )}
            />
        </label>
    );
};

export default Input;
