import { createSlice } from '@reduxjs/toolkit';

const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    loader: false,
  },
  reducers: {
    isLoading(state, action) {
      state.loader = action.payload.loader;
    },
  },
});

export const { isLoading } = loaderSlice.actions;

export default loaderSlice.reducer;
