import React, {useState} from 'react';

const Dropdown = (props) => {
    const [open, setOpen] = useState(false);
    return (
        <div
            className={`faq__item ${open ? 'open' : ''}`}
            onClick={() => setOpen(!open)}
        >
            <div className='faq__top' style={{color: open && 'white'}}>{props.title}</div>
            <div
                className='faq__bottom'
                style={open ? {maxHeight: 'fit-content'} : null}
                dangerouslySetInnerHTML={{__html: props.desc}}
            ></div>
        </div>
    );
};
export default Dropdown;
