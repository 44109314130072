//Import React
import React, {useEffect, useState} from 'react';
import Input from "../components/Input/Input";
import InputPassword from "../components/Input/InputPassword";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {FormProvider, useForm} from "react-hook-form";
import axios from "axios";
import {isLoading} from "../store/loaderSlice";
import {useDispatch, useSelector} from "react-redux";
import {isSign} from "../store/userAuthSlice";
import {updateData} from "../store/userSlice";


const RecoveryPassword = () => {
    const userData = useSelector((state) => state.user.data);
    const location = useLocation();
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [writeToken, setWhiteToken] = useState(null);

    useEffect(() => {
        const writeTokenLS = localStorage.getItem('writeToken');
        const writeTokenParse = JSON.parse(writeTokenLS);
        setWhiteToken(writeTokenParse);
    }, []);
    const [isDone, setIsDone] = useState(false)
    const [user, setUser] = useState(null)
    const methods = useForm()
    const onSubmit = (data) => {
        console.log(data.password, data.confirmPassword)
        if (data.password === data.confirmPassword && writeToken !== null) {
            console.log(data.password, data.confirmPassword)
            console.log('done')
            axios.post(`https://figmaai-backend.herokuapp.com/update-password/`, {password: data.password, writeToken: writeToken})
                .then(res => {

                    dispatch(isLoading({loader: true}));

                    setTimeout(() => {
                        console.log(res.data)
                        setUser({password: data.password, email: res.data.email})
                        setIsDone(true)
                        dispatch(isLoading({loader: false}));
                    }, 1000);
                })
               
        } else {
            methods.setError("password", {
                type: "manual",
                message: 'Passwords do not match',
            })
            methods.setError("confirmPassword", {
                type: "manual",
                message: 'Passwords do not match',
            })
        }

    }
    const login = (email, password) => {

        axios
            .post('https://figmaai-backend.herokuapp.com/login', {
                login: email,
                password: password,
                writeToken: writeToken !== null ? writeToken : '',
            })
            .then((res) => {
                console.log('login', res);
                if (res.status === 200) {
                    dispatch(isSign())
                    dispatch(
                        updateData({
                            token: res.data.accessToken,
                            refreshToken: res.data.refreshToken,
                            email: email,
                            loginned: true,
                        })
                    );

                    localStorage.setItem('userData', JSON.stringify(userData));
                    navigate('/profile');
                }
            })
            .catch((error) => {
                if (error.response.data.message === "Invalid password!") {
                    methods.setError("password", {
                        type: "manual",
                        message: error.response.data.message,
                    })
                } else {
                    methods.setError("email", {
                        type: "manual",
                        message: error.response.data.message,
                    })
                }

            })

    }
    return (
        <>
            {isDone ? <>
                <div className={'forgot'}>
                    <div className={'forgot__content'}>
                        <div className="forgot__ico">
                            <svg width="80" height="80" viewBox="0 0 80 80" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M33.9231 65C33.4102 64.9984 32.9032 64.8914 32.4334 64.6857C31.9637 64.48 31.5413 64.18 31.1926 63.8044L13.0139 44.4875C12.3344 43.7641 11.9703 42.8007 12.0019 41.8092C12.0335 40.8177 12.458 39.8793 13.1822 39.2005C13.9064 38.5217 14.8709 38.1581 15.8635 38.1896C16.856 38.2212 17.7954 38.6453 18.475 39.3687L33.8857 55.7712L65.3431 21.3969C65.6623 20.9998 66.0594 20.6721 66.5101 20.4339C66.9607 20.1957 67.4553 20.052 67.9636 20.0117C68.4718 19.9714 68.983 20.0353 69.4656 20.1995C69.9482 20.3638 70.3921 20.6248 70.77 20.9666C71.148 21.3084 71.452 21.7238 71.6634 22.1873C71.8748 22.6507 71.989 23.1524 71.9992 23.6616C72.0094 24.1708 71.9153 24.6767 71.7227 25.1482C71.53 25.6197 71.2429 26.0469 70.8789 26.4036L53.785 45.0853L36.6911 63.767C36.3456 64.1494 35.9247 64.4564 35.4548 64.6686C34.9849 64.8808 34.4762 64.9936 33.9605 65H33.9231Z"
                                    fill="white"/>
                            </svg>
                        </div>
                        <p className="sign__title">
                            Your password has been updated.
                        </p>
                        <button
                            className='btn-main btn-main--large btn-main--purple'
                            type='submit'
                            onClick={() => login(user.email, user.password)}
                        >

                            Go to my account
                        </button>
                    </div>


                </div>
            </> : <>
                <div className={'forgot'}>
                    <div className={'forgot__content'}>
                        <p className="recovery__title sign__title">
                            Create a new password for your account
                        </p>

                        <FormProvider {...methods}>
                            <form className='forgot__form' onSubmit={methods.handleSubmit(onSubmit)}>
                                <div className="sign__form-inputs">
                                    <InputPassword
                                        object_key={'password'}
                                        name={'password'}
                                        placeholder={'Password'}
                                        required
                                    />
                                    <InputPassword
                                        object_key={'confirmPassword'}
                                        name={'confirmPassword'}
                                        placeholder={'Confirm Password'}
                                        required
                                    />

                                </div>

                                <button
                                    className='btn-main btn-main--large btn-main--purple'
                                    type='submit'>
                                    Reset Password
                                </button>

                            </form>


                        </FormProvider>


                    </div>


                </div>
            </>}

        </>
    );
};
export default RecoveryPassword;
