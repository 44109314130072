import React from 'react';
import loaderIco from '../../img/loader-wh.png';

const Loader = () => {
  return (
    <div className='loader'>
      <div className='loader__ico'>
        <img src={loaderIco} alt='' />
      </div>
    </div>
  );
};

export default Loader;
