// Import React
import React, {useState, useEffect} from 'react';

//Import react-intersection-observer
import {useInView} from 'react-intersection-observer';

//Import components

import Dropdown from '../Dropdown/Dropdown';

const Faq = () => {
    const [animation, setAnimation] = useState(false);
    const {ref, inView, entry} = useInView({
        /* Optional options */
        threshold: 0,
    });

    useEffect(() => {
        if (inView === true) {
            setAnimation(true);
        }
    }, [inView]);
    return (
        <section
            className={`section-start faq pt ${animation && 'animation'}`}
            id='faq'
            ref={ref}
        >
            <div className='container-l'>
                <h2 className={`title__h2`}>FAQs for AI Designer Pro</h2>
                <div className='faq__wrapper'>
                    <Dropdown
                        title={'Why do designers need this plugin?'}
                        desc={`Who needs to bother with monotonous tasks like searching for appropriate images and GIFs, or creating UX artifacts from scratch? Just let AI do all the heavy lifting and watch your processes magically accelerate 1000 times faster.`}
                    />
                    <Dropdown
                        title={'How accurate are the AI-generated results?'}
                        desc={`We continuously test and update all features, striving to improve them every day. However, it's always important to review the initial result and remember that AI is not magic. Additionally, the final result is particularly influenced by your text prompt, and even small changes can lead to a better outcome. There are also additional parameters that are configured separately and that also affect the result.`}
                    />


                    <Dropdown
                        title={'How to achieve a desired result with AI?'}
                        desc={`To achieve a desired result with AI, it's important to start with a well-crafted prompt. The quality and specificity of the prompt will assist you. We recommend studying examples of text prompts and generation results in our <a target="_blank" href="https://www.figma.com/file/mkmGnThu1I72M1UXR9JoTC/AI-Designer-Pro-Playground?type=design&node-id=0%3A1&mode=design&t=XmK58AMB8aU6u9DN-1">playground file</a> to gain insights and improve your approach. Experimentation and fine-tuning will help you optimize AI-generated content to meet your unique goals.`}

                    />

                    <Dropdown
                        title={'Do I have rights to the AI-generated content?'}
                        desc={`Yes, you have the legal right to use and distribute any AI-generated content created within our plugin, whether it's text, images, or animations. Moreover, you are allowed to duplicate text prompts and content generated within our plugin's community.`}
                    />
                    <Dropdown
                        title={'Why do I need to link my email?'}
                        desc={`An email address is required to create your account in AI Designer Pro and access our products. We are committed to protecting your data privacy and do not store or share your information with third parties, in accordance with our privacy policy.`}
                    />  <Dropdown
                        title={'How can I suggest adding a feature?'}
                        desc={`Your feedback is crucial to us as we strive to make our plugin more user-friendly and to assist you in achieving your design goals. Please feel free to propose new features or enhancements by contacting us at <a href="mailto:support@aidsnpro.com">support@aidsnpro.com</a>, and we will review all your suggestions.`}
                    />
                </div>
            </div>
        </section>
    );
};

export default Faq;
