export function countDaysBetweenDates(created_at, ends_at) {
    // Extract year, month, and day from the date strings
    const startDateParts = created_at.match(/\d+/g);
    const endDateParts = ends_at.match(/\d+/g);

    if (!startDateParts || !endDateParts || startDateParts.length < 3 || endDateParts.length < 3) {
        throw new Error('Invalid date format');
    }

    const startYear = parseInt(startDateParts[0]);
    const startMonth = parseInt(startDateParts[1]) - 1; // Months are 0-based
    const startDay = parseInt(startDateParts[2]);

    const endYear = parseInt(endDateParts[0]);
    const endMonth = parseInt(endDateParts[1]) - 1;
    const endDay = parseInt(endDateParts[2]);

    // Create Date objects
    const startDate = new Date(Date.UTC(startYear, startMonth, startDay));
    const endDate = new Date(Date.UTC(endYear, endMonth, endDay));

    // Calculate the time difference in milliseconds
    const timeDifference = endDate - startDate;

    // Convert milliseconds to days
    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));


    // Return the number of days as an integer
    if(daysDifference === 0) {
        return 'Expired'
    } else {
        return `${daysDifference} days left`
    }

}