//Assets
import img01 from '../../../../img/bendo/starship-optimize.gif'
import img02 from '../../../../img/bendo/anime-optimize.gif'

const GenerateAnimations = ({grid}) => {
    return (
        <div className={`bento-ga__block ${grid}`}>

            <h3 className={'bento__title-h4'}>Generate Animations</h3>
            <div className={`bento-ga__images`}>
                <div className={'bento-ga__image-block'}>
                    <img src={img01} alt={'Generate Animations'}/>
                </div>
                <div className={'bento-ga__image-block'}>
                    <img src={img02} alt={'Generate Animations'}/>
                </div>
            </div>


        </div>
    )
}
export default GenerateAnimations