//Assets
import img01 from '../../../../img/bendo/Starship.gif'
import img02 from '../../../../img/bendo/anime.gif'
import GC_Text_Svg from "./GC_Text_Svg";
import React from "react";

const GenerateCopy = ({grid}) => {
    return (
        <div className={`bento-gc__block ${grid}`}>

            <h4 className={'bento__title-h4'}>Generate Copy</h4>
            <div className={`bento-gc__copy`}>

                <div className={'bento-gc__img'}>

                </div>
                <div className={'bento-gc__btns'}>
                    <div className='bento-gc__keyword'>Rephrase</div>
                    <div className='bento-gc__keyword'>Expand</div>
                    <div className='bento-gc__keyword'>Reduce</div>
                    <div className='bento-gc__keyword'>Translate</div>
                    <div className='bento-gc__keyword'>Fix Grammar</div>
                </div>
            </div>


        </div>
    )
}
export default GenerateCopy