import { configureStore } from '@reduxjs/toolkit';
import userReducer from './userSlice';
import loaderReducer from './loaderSlice';
import userAuthReducer from './userAuthSlice';

export default configureStore({
  reducer: {
    user: userReducer,
    loader: loaderReducer,
    userAuth: userAuthReducer,
  },
});
