

//Assets
import img01 from '../../../../img/bendo/gi-1.png'
import img02 from '../../../../img/bendo/gi-2.png'
import img03 from '../../../../img/bendo/gi-3.png'
import {useEffect, useState} from "react";
import {useInView} from "react-intersection-observer";


const GenerateImages = ({grid}) => {
    const [animation, setAnimation] = useState(false);
    const {ref, inView, entry} = useInView({
        /* Optional options */
        threshold: 0,
    });
    useEffect(() => {
        if (inView === true) {
            setTimeout(() => {
                setAnimation(true)
            }, 500)
        }
    }, [inView]);
    return (
        <div className={`bento-gi__block ${grid}`}>

                <h3 className={'bento__title-h4'}>Generate Images</h3>
                <div className={`bento-gi__image`} ref={ref}>
                    <svg  className={`bento-gi__snake ${animation && 'bento-gi__snake-animation'}`} width="84" height="76" viewBox="0 0 84 76" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 22.1682C2 22.1682 36.0984 -5.18299 43.9102 3.81777C51.722 12.8185 12.8909 44.7711 22.2423 48.3332C31.5937 51.8953 59.4198 26.9091 65.464 31.7234C71.5082 36.5378 51.608 55.7952 48.814 70.029C46.02 84.2628 82 55.6557 82 55.6557" stroke="#E6E6E6" stroke-width="4"/>
                    </svg>
                </div>


        </div>
    )
}
export default GenerateImages