import { createSlice } from '@reduxjs/toolkit';

const userAuth = createSlice({
  name: 'userAuth',
  initialState: {
    isSign: false,
  },

  reducers: {
    isSign(state) {
      state.isSign = !state.isSign
    },
  },
});
export const { isSign } = userAuth.actions;

export default userAuth.reducer;
