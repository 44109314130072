//Import React
import React, {useEffect, useState, useRef} from 'react';

//Import Route
import {useLocation, useNavigate} from 'react-router-dom';

//Import Components
import SignUp from '../components/Authentication/SignUp';
import SignIn from '../components/Authentication/SignIn';
import {useDispatch, useSelector} from 'react-redux';
import {loader} from '../store/loaderSlice';
import Input from "../components/Input/Input";
import GoogleAuth from "../components/Authentication/GoogleAuth";

const Sign = () => {
    const signUpRef = useRef(null);
    const signInRef = useRef(null);

    const [currentTab, setCurrentTab] = useState('signIn')

    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);

    const signParams = {
        sign: searchParams.get('sign'),
    };


    const user = useSelector((state) => state.user.data);
    const isSign = useSelector((state) => state.userAuth.isSign);

    useEffect(() => {
        console.log(signParams.sign);
        if (user.loginned === true) {
            navigate('/profile');

            if (user.writeToken !== null && isSign === true) {
                navigate('/auth?status=done');
            } else if (user.writeToken) {
                navigate('/auth');
            }
        }
    }, [user]);

    useEffect(() => {
        console.log('signParams:', signParams);
        if (signParams.sign == 'singin') {
            setCurrentTab('singIn')

        } else if (signParams.sign == 'signup') {
            setCurrentTab('signUp')
        }
    }, [signParams, currentTab]);




    return (
        <>
            <div className="sign">
                <div className="sign__container">
                    <div className="sign__content">
                        <div className="sign__column-left">
                            <h1 className="sign__title">{currentTab === 'signUp' ? 'Create a free account to get started' : 'Sign in to your account'}</h1>
                            <p className="sign__subtitle">Join hundreds of designers already enhancing their creations
                                with AI Designer Pro.</p>
                        </div>
                        <div className="sign__column-right">
                            <div className="sign__toggle">
                                <span className={`sign__tab ${currentTab === 'signUp' && 'sign__tab-active'}`}
                                      ref={signUpRef} onClick={() => {
                                    navigate(`/sign?${user.writeToken ? `writeToken=${user.writeToken}&sign=signup`:`sign=signup`}`);
                                    setCurrentTab('signUp')
                                }}>Sign up</span>
                                <span className={`sign__tab ${currentTab === 'signIn' && 'sign__tab-active'}`}
                                      ref={signInRef} onClick={() => {
                                    navigate(`/sign?${user.writeToken ? `writeToken=${user.writeToken}&sign=signin`:`sign=signin`}`);
                                    setCurrentTab('signIn')

                                }}>Sign in</span>
                                <div className="sign__runner" style={currentTab === 'signIn' ? {
                                    width: signInRef.current && signInRef.current.clientWidth,
                                    left: `calc(100% - ${signInRef.current && signInRef.current.clientWidth}px)`,
                                } : {width: signUpRef.current && signUpRef.current.clientWidth, left: 0}}></div>
                            </div>
                            <GoogleAuth sign={currentTab}/>
                            <div className={'sign__separator'}>
                                <span>or</span>
                            </div>
                            {currentTab === 'signUp' ? <SignUp/> : <SignIn/>}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default Sign;
