import './styles/Bento.scss'
import GenerateAnimations from "./Blocks/GenerateAnimations";
import GenerateCopy from "./Blocks/GenerateCopy";
import GenerateUserPersonas from "./Blocks/GenerateUserPersonas";
import GenerateMindMaps from "./Blocks/GenerateMindMaps";
import GenerateSiteMaps from "./Blocks/GenerateSiteMaps";
import GenerateUJMs from "./Blocks/GenerateUJMs";
import GenerateImages from "./Blocks/GenerateImages";
import GenerateImageVariants from "./Blocks/GenerateImageVariants";
import {useEffect, useState} from "react";
import {useInView} from "react-intersection-observer";

const Bento = () => {
    const [animation, setAnimation] = useState(false);
    const {ref, inView, entry} = useInView({
        /* Optional options */
        threshold: 0.2,
    });

    useEffect(() => {
        if (inView === true) {
            setTimeout(() => {
                setAnimation(true)
            }, 500)
        }
    }, [inView]);
    return (
        <div className={`section-start bento ${animation && 'animation'}`}  ref={ref}>
            <div className={'bento__container'}>
                <div className="bento__title">
                    <h2 className={'title__h2'}>Design at the speed of imagination</h2>
                    <p>One plugin helps you with all of these tasks. You only need to visualize it.</p>
                </div>
                <div className="bento__content">
                    <GenerateAnimations />
                    <GenerateCopy />



                </div>

                <div className={`bento__subgrid`}>
                    <GenerateUserPersonas />
                    <GenerateMindMaps />
                    <GenerateSiteMaps />
                    <GenerateUJMs />

                </div>
                <div className="bento__content">
                    <GenerateImages />
                    <GenerateImageVariants />
                </div>


            </div>
        </div>

    )
}

export default Bento