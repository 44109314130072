import logo from "../../img/logo.svg";
import React, {useEffect, useState} from "react";
import {shortenText} from "../../tools/shortenText";
import {removeWordFromText} from "../../tools/removeWordFromText";
import Alert from "../Icons/Alert";
import CheckMark from "../Icons/CheckMark";
import {countDaysBetweenDates} from "../../tools/countDaysBetweenDates";

function Subscription({data, days_left}) {


    return (
        <div className='subscription'>
            <div className='subscription-top'>
                <div className='subscription-top__logo'>
                    <img loading='lazy' src={logo} alt=''/>

                    <div className='user__plan-name'>{shortenText(removeWordFromText(data.name))}</div>
                    {data.status === 'cancelled' ? <Alert/> : <CheckMark/>}

                </div>
                {data.status === 'cancelled' ? <div
                    className={'user__plan-days-left'}>{days_left}</div> : <a
                    href='https://www.figma.com/community/plugin/1261721642878814305'
                    target='_blank'
                    className='btn-secondary btn-secondary--light btn-secondary--very-small'
                >
                    Visit
                </a>}

            </div>
            <>
                <ul className='subscription__list'>
                    <li className='subscription__item'>
                        50 Animation Generations
                    </li>

                    <li className='subscription__item'>
                        500 Image Generations
                    </li>
                    <li className='subscription__item'>
                        Unlimited Instant Copywriting
                    </li>
                    <li className='subscription__item'>
                        Unlimited Instant UX
                    </li>
                    <li className='subscription__item'>
                        Free 7-Day Trial
                    </li>
                    <li className='subscription__item'>
                        All Future Updates
                    </li>
                </ul>
            </>

        </div>
    )
}


export default Subscription