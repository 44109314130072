//React
import React, { useEffect, useRef, useState } from "react";

//Import Redux
import { useDispatch, useSelector } from "react-redux";
import { updateData, userLogout } from "../../store/userSlice";
import { isLoading, loader } from "../../store/loaderSlice";

//Import Route

import { Link, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

//Import react-intersection-observer
import { useInView } from "react-intersection-observer";
//
import logo from "../../img/logo.png";
// import logoWh from '../../img/logo-wh.png';
import person from "../../img/person.svg";
import axios from "axios";

const Header = ({ animation }) => {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const loginState = useSelector((state) => state.user.data.loginned);
  const user = useSelector((state) => state.user.data);
  const [popup, openPopup] = useState(false);

  let headerClass = "";

  if (location.pathname === "/") {
    headerClass = "header";
  } else if (location.pathname === "/sign" || location.pathname === "/forgot") {
    headerClass = "header header-sign";
  } else {
    headerClass = "header";
  }

  useEffect(() => {
    // добавляем обработчик события click на элемент document
    document.addEventListener("click", handleOutsideClick);

    return () => {
      // удаляем обработчик события click при размонтировании компонента
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      // закрываем меню, если клик произошел вне выпадающего меню
      openPopup(false);
    }
  };

  const logout = () => {


    dispatch(userLogout());
    const broadcastChannel = new BroadcastChannel('logoutChannel');
    broadcastChannel.postMessage('logout');
    broadcastChannel.close();

    dispatch(isLoading({ loader: true }));
    setTimeout(() => {
      dispatch(isLoading({ loader: false }));
    }, 1500);
    openPopup(false);
    navigate("/");
  };

  function shortenString(str) {
    if (str.length > 17) {
      return str.substring(0, 17) + "...";
    } else {
      return str;
    }
  }

  const [isAnimation, setAnimation] = useState(false);
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
  });

  useEffect(() => {
    if (inView === true) {
      setTimeout(() => {  setAnimation(true)}, 1500)
    }
  }, [inView]);

  return (
    <header
      className={`${headerClass} ${
        animation === true ? "section-start " : ""
      } ${animation === true && isAnimation && "header-animation"}`}
      ref={ref}
    >
      <div className="container">
        <Link to={"/"} className="logo">
          <div className="logo__img">
            <img src={logo} alt="img" />
          </div>
          AI Designer Pro
        </Link>
        <nav className="nav">
          <ul>
            <li>
              <HashLink className="scroll-to" to={"/#video"}>
                Features
              </HashLink>
            </li>
            <li>
              <HashLink className="scroll-to" to={"/#pricing"}>
                Pricing
              </HashLink>
            </li>
            <li>
              <HashLink className="scroll-to" to={"/#faq"}>
                FAQ
              </HashLink>
            </li>
            <li>
              <a
                href="https://www.figma.com/community/plugin/1261721642878814305"
                target="_blank"
              >
                Install
              </a>
            </li>
          </ul>
        </nav>
        <div className="header__right">
          {loginState === true ? (
            <>
              <a
                ref={dropdownRef}
                href="#"
                className="btn-sign-done"
                onClick={() => openPopup(!popup)}
              >
                <img src={person} alt="img" />
              </a>
              <div className={`popup-nav ${popup ? "active" : ""}`}>
                <h2 style={{ color: "#111111" }}>
                  {shortenString(user.email)}
                </h2>
                <ul>
                  <li>
                    <Link to={"/profile"}>Account</Link>
                  </li>
                  <li>
                    <a href="#" onClick={logout}>
                      Log out
                    </a>
                  </li>
                </ul>
              </div>
            </>
          ) : (
            <Link
              to={"/sign"}
              className="btn-secondary btn-secondary--small button-secondary--dark"
            >
              Login
            </Link>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
