import axios from "axios";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {isLoading} from "../../store/loaderSlice";
import {userLogout} from "../../store/userSlice";

export const ProfileHeadCancel = ({token}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [isCancel, setIsCancel] = useState(false);

    const deleteSubscription = (token) => {
        console.log(token);
        axios
            .delete(`https://figmaai-backend.herokuapp.com/subscription`, {
                headers: {token: token},
            })
            .then((res) => {
                if (res.status == 200) {
                    setIsCancel(true);
                }
            })
            .catch((err) => {
                dispatch(userLogout())
                navigate('/sign')
                console.error(err)
            });
    };

    const pauseSubscription = (token) => {
        console.log(token);
        axios
            .patch(`https://figmaai-backend.herokuapp.com/subscription/pause`, {unpause: true}, {
                headers: {token: token},
            })
            .then((res) => {
                console.log(res)
                if (res.status == 200) {
                    // setIsCancel(true);
                }
            })
            .catch((err) => {
                dispatch(userLogout())
                navigate('/sign')
                console.error(err)
            });
    };

    return (
        <>
            {isCancel ? (
                <>
                    <h1 className="user-title">Your subscription is canceled</h1>
                    <p className="user-text">
                        Your subscription will no longer automatically renew. You will still have access to the plugin
                        features until the current subscription period expires.
                    </p>
                    <button
                        className="button-secondary button-secondary--dark button-secondary--small"
                        onClick={() => navigate("/")}
                    >
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.8789 3.75L18 9.87114L11.8789 15.9923"
                                stroke="white"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                            <path
                                d="M3 9.87109H17.8289"
                                stroke="white"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        Go home
                    </button>
                </>
            ) : (
                <>
                    <h1 className="user-title">We are sorry to see you go!</h1>
                    <p className="user-text">
                        We hope you've enjoyed using our plugin! We'll keep your account open for when you're ready to
                        come back. Charges will be suspended, and your subscription will not renew.
                    </p>
                    <div className="user-center">
                        <div className="user-buttons">
                            {/*<button className="button-main button-main--puprle button-main--large" onClick={() => pauseSubscription(token)}>*/}
                            {/*  Pause subscription*/}
                            {/*</button>*/}
                            <button
                                className="button-secondary button-secondary--dark button-secondary--large"
                                onClick={() => {
                                    deleteSubscription(token);

                                    navigate("/profile?subscription=cancel-done");
                                    dispatch(isLoading({loader: true}));
                                    setTimeout(() => {
                                        dispatch(isLoading({loader: false}));
                                        if (isCancel) {
                                            dispatch(isLoading({loader: false}));
                                        }
                                    }, 1000);
                                }}
                            >
                                Cancel subscription
                            </button>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};
