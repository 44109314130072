//Import React
import React, { useEffect, useState } from 'react';

//Import Redux
import { useDispatch, useSelector } from 'react-redux';

//Import Axios
import axios from 'axios';

//Import imgs
import figmaLogo from '../../img/figma-connect.svg';
import aidLogo from '../../img/aid-connect.svg';
import figmaIco from '../../img/Icons.svg';
import loaderIco from '../../img/Loading-Light.png';
import bg from '../../img/auth-bg.png';
import { useLocation, useNavigate } from 'react-router-dom';
import {isRefreshToken, updateWriteToken, userLogout} from '../../store/userSlice';

const Auth = () => {
  const [activeDot, setActiveDot] = useState(0);
  const [status, setStatus] = useState(false);
  const [connect, setConnect] = useState(false);
  const user = useSelector((state) => state.user.data);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const access_token = user.token;
  const write_token = user.writeToken;

  const config = {
    headers: {
      token: access_token,
    },
  };

  const searchParams = new URLSearchParams(location.search);
  const authParams = {
    status: searchParams.get('status'),
  };

  const connectingToFigma = () => {
    if (status === false) {
      setConnect(true);
      axios
          .post(
              `https://figmaai-backend.herokuapp.com/authorize`,
              { writeToken: write_token },
              config
          )
          .then((response) => {
            if (response.status === 200) {
              setTimeout(() => {
                setConnect(false);
                setStatus(true);
                localStorage.removeItem('writeToken');
                //  dispatch(updateWriteToken({ writeToken: null }));
                setTimeout(() => {
                  navigate('/profile');
                }, 2000);
              }, 3000);
            }
          })
          .catch((err) => {
            axios
                .post(
                    `https://figmaai-backend.herokuapp.com/refresh-token`,
                    {},
                    {
                      Headers: {
                        'Refresh-Token': user.refreshToken,
                        token: user.token,
                      },
                    }
                )
                .then((res) => {
                  dispatch(
                      isRefreshToken({
                        token: res.data.accessToken,
                        refreshToken: res.data.refreshToken,
                      })
                  );

                  axios
                      .post(
                          `https://figmaai-backend.herokuapp.com/authorize`,
                          { writeToken: write_token },
                          config
                      )
                      .then((res) => {
                        if (res.status === 200) {
                          setTimeout(() => {
                            setConnect(false);
                            setStatus(true);
                            localStorage.removeItem('writeToken');
                            //  dispatch(updateWriteToken({ writeToken: null }));
                            setTimeout(() => {
                              navigate('/profile');
                            }, 2000);
                          }, 3000);
                        }
                      });
                })
                .catch(err => {
                  dispatch(userLogout())
                  navigate('/sign')
                })
          });
    }
  };


  useEffect(() => {
    if (authParams.status === 'done') {
      setConnect(true);
      setTimeout(() => {
        setConnect(false);
        setStatus(true);
        localStorage.removeItem('writeToken');
        setTimeout(() => {
          navigate('/profile');
        }, 2000);
      }, 3000);
    }
  }, [authParams]);

  useEffect(() => {
    if (status === false) {
      const intervalId = setInterval(() => {
        setActiveDot((prevDot) => (prevDot + 1) % 3);
      }, 500);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, []);




  return (
    <div className='auth'>
      <div className='auth__content'>
        <div className='auth__connected'>
          <div className='auth__ico'>
            <img src={aidLogo} alt='' />
          </div>
          {status ? (
            <div className='auth__done'>
              <svg
                width='22'
                height='16'
                viewBox='0 0 22 16'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M8.17309 15.875C7.99358 15.8744 7.81611 15.837 7.65171 15.765C7.4873 15.693 7.33947 15.588 7.2174 15.4565L0.854865 8.69561C0.617025 8.44243 0.489615 8.10524 0.500663 7.75821C0.511711 7.41119 0.660313 7.08277 0.913778 6.84519C1.16724 6.60761 1.50481 6.48034 1.85221 6.49138C2.19962 6.50241 2.5284 6.65085 2.76624 6.90404L8.16 12.6449L19.1701 0.613899C19.2818 0.474918 19.4208 0.36022 19.5785 0.276851C19.7362 0.193483 19.9094 0.143203 20.0873 0.1291C20.2651 0.114997 20.444 0.137369 20.6129 0.194841C20.7819 0.252314 20.9372 0.343673 21.0695 0.463309C21.2018 0.582945 21.3082 0.728331 21.3822 0.890538C21.4562 1.05274 21.4962 1.22835 21.4997 1.40656C21.5033 1.58477 21.4704 1.76183 21.4029 1.92686C21.3355 2.0919 21.235 2.24142 21.1076 2.36625L15.1248 8.90485L9.14187 15.4435C9.02096 15.5773 8.87365 15.6847 8.70918 15.759C8.54472 15.8333 8.36666 15.8728 8.18618 15.875H8.17309Z'
                  fill='white'
                />
              </svg>
            </div>
          ) : (
            <div className='auth__loading'>
              <div
                className={`auth__loading-dot ${
                  activeDot === 0 ? 'auth__loading-dot-active' : ''
                }`}
              ></div>
              <div
                className={`auth__loading-dot ${
                  activeDot === 1 ? 'auth__loading-dot-active' : ''
                }`}
              ></div>
              <div
                className={`auth__loading-dot ${
                  activeDot === 2 ? 'auth__loading-dot-active' : ''
                }`}
              ></div>
            </div>
          )}

          <div className='auth__ico'>
            <img src={figmaLogo} alt='' />
          </div>
        </div>
        <div className='auth__text'>
          <h1>
            {status
              ? `Great, you're logged in Figma Plugin!`
              : `Connect your AI Designer Pro account to Figma`}
          </h1>
          <p>
            {status
              ? `Now head back to the Figma Plugin and let AI do all the magic. `
              : `to use powerful AI tools for your design.`}
          </p>
          {status ? (
            <></>
          ) : (
            <a
              href='#'
              className='button-secondary button-secondary--dark button-secondary--large'
              onClick={connectingToFigma}
            >
              {connect ? (
                <>
                  <div
                    className='loader__ico'
                    style={{ width: 20, height: 20 }}
                  >
                    <img src={loaderIco} alt='' />
                  </div>
                  Connecting...
                </>
              ) : (
                <>
                  <img src={figmaIco} alt='img' />
                  Connect to Figma
                </>
              )}
            </a>
          )}
        </div>
      </div>
      {/*<div className={`auth__bg `}>*/}
      {/*  /!*<img src={bg} alt='' />*!/*/}
      {/*</div>*/}
    </div>
  );
};

export default Auth;
