import React, {useState, useEffect} from "react";
import axios from "axios";
import GoogleLogo from '../../img/google.svg';
import loaderIco from '../../img/loader.png';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {updateData, updateWriteToken} from "../../store/userSlice";
import {isSign} from "../../store/userAuthSlice";

function GoogleAuth({sign}) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = useSelector((state) => state.user.data);

    const [connect, setConnect] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const authParams = {
        state: searchParams.get('state'),
        code: searchParams.get('code'),
        scope: searchParams.get('scope'),
        authuser: searchParams.get('authuser'),
        prompt: searchParams.get('prompt'),
        writeToken: searchParams.get('writeToken'),
    };
    const writeTokenLS = localStorage.getItem('writeToken');
    const writeTokenParse = JSON.parse(writeTokenLS);
    useEffect(() => {

        if (writeTokenParse) {
            dispatch(updateWriteToken({writeToken: writeTokenParse}));
        }
    }, []);

    function success(code, state, writeToken) {
        axios
            .post(
                `https://figmaai-backend.herokuapp.com/api/1.0/users/register/social/success`,
                {
                    code: code,
                    state: state,
                    writeToken: writeToken,
                }
            )
            .then((res) => {
                if (res.status == 200) {

                    dispatch(isSign())
                    dispatch(
                        updateData({
                            token: res.data.accessToken,
                            refreshToken: res.data.refreshToken,
                            email: res.data.email,
                            loginned: true,
                        })
                    );


                    localStorage.setItem('userData', JSON.stringify(userData));

                    if (userData.writeToken !== null) {
                        navigate('/auth?status=done')
                    } else {
                        navigate('/profile');
                    }

                }
            });
    }


useEffect(() => {
    if (authParams.state !== null) {
        success(authParams.code, authParams.state, writeTokenParse)

    }


}, []);

const googleSing = (tokenResponse) => {
    setConnect(true);
    console.log(tokenResponse);
    axios
        .post(
            `https://figmaai-backend.herokuapp.com/api/1.0/users/register/social`,
            {
                prodApiKey: '',
                provider: 'GOOGLE',
                redirectUrl: 'https://aidsnpro.com/sign',
            }
        )
        .then((res) => {
            console.log(res);
            window.location.replace(res.data.url);
        });
};

return (
    <span
        className={`btn-secondary btn-secondary--light-with-ico btn-secondary--large ${
            connect && 'btn-loading'
        }`}
        onClick={googleSing}
    >

            {connect ? (
                <>
                    <div className='loader__ico' style={{width: 20, height: 20}}>
                        <img src={loaderIco} alt=''/>
                    </div>
                </>
            ) : <img loading='lazy' src={GoogleLogo} alt='img'/>}

        <span data-hover='Sign in with Google'>{sign === 'signIn' ? 'Sign in' : 'Sign up'} with Google</span>
      </span>
)
}

export default GoogleAuth