import React from "react";

export default function Alert() {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
             xmlns="http://www.w3.org/2000/svg">
            <path d="M8.99902 5.19092V10.0889" stroke="white" stroke-width="1.2"
                  stroke-linecap="round"/>
            <circle cx="8.99894" cy="12.538" r="0.816326" fill="white"/>
            <path
                d="M17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17C13.4183 17 17 13.4183 17 9Z"
                stroke="white" stroke-width="1.2" stroke-miterlimit="10"/>
        </svg>
    )
}