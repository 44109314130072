
const GenerateMindMaps = ({grid}) => {
    return (
        <div className={`bento-gmm__block bento-gmm__block-gmm ${grid}`}>

            <h3 className={'bento__title-h4'}>Generate Mind Maps</h3>
            <div className={`bento-gmm__img`}>
            </div>


        </div>
    )
}
export default GenerateMindMaps