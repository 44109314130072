export function shortenText(text) {
    const screenWidth = window.innerWidth;

    if (screenWidth <= 380 && text.length > 8) {
        const maxLength = 8;

        return `${text.slice(0, maxLength)}...`;
    }

    return text;
}