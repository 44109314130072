import React from "react";

export default function CheckMark() {
    return (
        <svg
            width='12'
            height='10'
            viewBox='0 0 12 10'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <path
                d='M4.38462 9.5C4.28205 9.49967 4.18064 9.47827 4.08669 9.43713C3.99274 9.396 3.90827 9.336 3.83852 9.26087L0.20278 5.39749C0.0668714 5.25282 -0.00593442 5.06013 0.000378846 4.86184C0.00669211 4.66354 0.0916074 4.47587 0.236444 4.34011C0.381281 4.20435 0.574176 4.13162 0.772693 4.13793C0.97121 4.14424 1.15909 4.22906 1.295 4.37373L4.37714 7.65424L10.6686 0.779371C10.7325 0.699953 10.8119 0.634412 10.902 0.586772C10.9921 0.539133 11.0911 0.510401 11.1927 0.502343C11.2944 0.494284 11.3966 0.507068 11.4931 0.539909C11.5896 0.572751 11.6784 0.624956 11.754 0.69332C11.8296 0.761683 11.8904 0.844761 11.9327 0.93745C11.975 1.03014 11.9978 1.13048 11.9999 1.23232C12.0019 1.33416 11.9831 1.43533 11.9445 1.52964C11.906 1.62394 11.8486 1.70938 11.7758 1.78071L8.357 5.51706L4.93821 9.2534C4.86912 9.32989 4.78494 9.39128 4.69096 9.43371C4.59698 9.47615 4.49523 9.49872 4.39211 9.5H4.38462Z'
                fill='white'
            />
        </svg>
    )
}