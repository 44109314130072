//Import React
import React, {useEffect, useState} from 'react';

//Import Route
import {useNavigate, useLocation, Link} from 'react-router-dom';

//Import React-Hook-Form

import {useForm, FormProvider} from "react-hook-form"


//Import Axios
import axios from 'axios';

//Import Redux
import {useSelector, useDispatch} from 'react-redux';
import {updateData, updateWriteToken} from '../../store/userSlice';

//Import components
import Input from '../Input/Input';

//Import Images
import GoogleLogo from '../../img/google.svg';
import loaderIco from '../../img/loader.png';
import {isSign} from '../../store/userAuthSlice';
import InputPassword from "../Input/InputPassword";

const SignIn = () => {
    // const [writeToken, setWhiteToken] = useState(null);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = useSelector((state) => state.user.data);
    const writeToken = useSelector((state) => state.user.data.writeToken);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [connect, setConnect] = useState(false);

    const authParams = {
        state: searchParams.get('state'),
        code: searchParams.get('code'),
        scope: searchParams.get('scope'),
        authuser: searchParams.get('authuser'),
        prompt: searchParams.get('prompt'),
    };
    const writeTokenLS = localStorage.getItem('writeToken');
    const writeTokenParse = JSON.parse(writeTokenLS);
    useEffect(() => {

        if (writeTokenParse) {
            dispatch(updateWriteToken({writeToken: writeTokenParse}));
        }
    }, []);


    const googleSing = (tokenResponse) => {
        setConnect(true);
        console.log(tokenResponse);
        axios
            .post(
                `https://figmaai-backend.herokuapp.com/api/1.0/users/register/social`,
                {
                    prodApiKey: '',
                    provider: 'GOOGLE',
                    redirectUrl: 'https://aidsnpro.com/sign',
                }
            )
            .then((res) => {
                console.log(res);
                window.location.replace(res.data.url);
            });
    };

    useEffect(() => {
        // Получаем текущий URL
        const currentUrl = window.location.href;

        // Получаем значение параметра state
        const state = new URLSearchParams(window.location.search).get('state');

        // Получаем значение параметра code
        const code = new URLSearchParams(window.location.search).get('code');

        // Получаем значение параметра scope
        const scope = new URLSearchParams(window.location.search).get('scope');

        // Получаем значение параметра authuser
        const authuser = new URLSearchParams(window.location.search).get(
            'authuser'
        );

        // Получаем значение параметра prompt
        const prompt = new URLSearchParams(window.location.search).get('prompt');
        console.log(currentUrl);
    }, []);



    const login = (email, password) => {

        axios
            .post('https://figmaai-backend.herokuapp.com/login', {
                login: email,
                password: password,
                writeToken: writeToken !== null ? writeToken : '',
            })
            .then((res) => {
                console.log('login', res);
                if (res.status === 200) {
                    dispatch(isSign())
                    dispatch(
                        updateData({
                            token: res.data.accessToken,
                            refreshToken: res.data.refreshToken,
                            email: email,
                            loginned: true,
                        })
                    );

                    localStorage.setItem('userData', JSON.stringify(userData));
                    navigate('/profile');
                }
            })
            .catch((error) => {
                if (error.response.data.message === "Invalid password!") {
                    methods.setError("password", {
                        type: "manual",
                        message: error.response.data.message,
                    })
                } else {
                    methods.setError("email", {
                        type: "manual",
                        message: error.response.data.message,
                    })
                }

            })

    }

    const methods = useForm()

    const onSubmit = (data) => {
        login(data.email, data.password)
    }
    const onError = (errors, e) => console.log(errors, e);

    return (
        <FormProvider {...methods}>
            <form className='sign__form' onSubmit={methods.handleSubmit(onSubmit, onError)}>
                <div className="sign__form-inputs">
                    <Input
                        object_key={'email'}
                        name={'email'}
                        placeholder={'Email address'}
                        required
                    />
                    <InputPassword
                        object_key={'password'}
                        name={'password'}


                        placeholder={'Password'}
                        required
                    />
                </div>

                <button
                    className='btn-main btn-main--large btn-main--purple'
                    type='submit'>
                    Sign in
                </button>

            </form>
            <Link className={'sign__forgot'} to={'/forgot'}>Forgot your password</Link>

        </FormProvider>
    );
}


export default SignIn;
