import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {isLoading} from "../../store/loaderSlice";
import {Link, useNavigate} from "react-router-dom";

export const ProfileHead = ({data, days_left, reSubscribe}) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user.data);
    const navigate = useNavigate();

    return (
        <>
            <h1 className="user-title">Account</h1>
            <p className="user-email">{user.email}</p>
            <>
                <div className="user-center">
                    <h3>Billing</h3>
                    <div className="user-buttons">
                        {days_left === 'expired' || data.status == "expired" || data.status == "cancelled"? <a
                                onClick={() => reSubscribe()}
                                style={{cursor: 'pointer'}}
                                className="button-main button-main--puprle button-main--large"
                            >
                                Re-subscribe
                            </a> :

                            data.status !== "cancelled" && data.status !== "expired" && (<a
                                href={data.urls.update_payment_method}
                                target="_blank"
                                className="button-main button-main--puprle button-main--large"

                            >
                                Manage billing
                            </a>)

                        }

                        <a
                            className="button-secondary button-secondary--dark button-secondary--large"

                            href="https://app.lemonsqueezy.com/my-orders"
                            target="_blank"
                        >
                            Payment history
                        </a>
                    </div>
                    {data.status !== "cancelled" && data.status !== "expired" && <span
                        className="user-center-text user-cancel"
                        // href={user.subscript.url}
                        style={{cursor: "pointer"}}
                        onClick={() => {
                            dispatch(isLoading({loader: true}));
                            setTimeout(() => {
                                dispatch(isLoading({loader: false}));
                                navigate("/profile?subscription=cancel");
                            }, 1000);
                        }}
                    >
              Cancel subscription
            </span>}

                </div>
            </>

        </>
    );
};
